<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="分账组" class="ui-form__item" name="allocateGroupId">
						<a-select v-model:value="formState.allocateGroupId" style="width: 180px;" placeholder="请选择分账组">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="卖品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称"/>
					</a-form-item>

					<a-form-item label="卖品状态" name="isShelf">
						<a-select style="width: 180px;" v-model:value="formState.isShelf" placeholder="请选择卖品状态">
							<a-select-option :value="1">已上架</a-select-option>
							<a-select-option :value="0">已下架</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ledger_setting_snack_group']" type="primary" :disabled="!selectedRowKeys.length" @click="onSelect(1)">批量设置分账组</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
<!--			<div style="margin-top: 10px;color: red;">注:当前默认组为【{{ title || '' }}】，列表中以"-"表示</div>-->
      <div style="margin-top: 10px;color: red;">
        <p>注: 卖品没有设置分账组则使用【影院卖品分账组】卖品所属的影院的分账组进行分账，</p>
        <p>如果【影院卖品分账组】的影院没有配置分账组，则使用默认分账组进行分账</p>
      </div>
			<a-table :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 2600 }">
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'goods'">
						<div>
							卖品标题：<span style="color: #666;"> {{ record.mainTitle }} </span>
						</div>
						<div>
							卖品分类: <span style="color: #999;">{{ record.classifyName }}</span>
						</div>
					</template>

					<template v-if="column.key === 'img'">
						<a-image :src="record.imgUrl.split(',')[0]" style="width: 60px;"></a-image>
					</template>

					<template v-if="column.key === 'card'">
						<div v-if="record.isCard">
							<a-tag color="orange">{{ record.cardType === 1 ? '次卡' : '年卡' }}</a-tag>
							<div v-if="record.cardType === 1">观影次数：{{ record.cardBalanceTimes }}</div>
							<div>卡等级：{{ record.cardTitle }}</div>
							<div>
								有效期：
								<span v-if="record.validityType === 1">永不过期</span>
								<span v-else>
									<span v-if="record.validityType !== 5">
										{{ record.validityValue }} {{ ['年', '月', '日'][record.validityType - 2] }}
									</span>
									<span v-else>{{ transDateTime(record.validityValue, 1) }}</span>
								</span>
							</div>
						</div>
						<span v-else>--</span>
					</template>

					<template v-if="column.key === 'part'">
						<div>
							<a>浏览量：{{ record.recordNum }}</a>
						</div>
						<div>
							<a>点赞数：{{ record.praiseNum }}</a>
						</div>
						<!-- <a>评论数：{{ record. }}</a> -->
						<div>
							<a>收藏数：{{ record.collectNum }}</a>
						</div>
					</template>

					<template v-if="column.key === 'price'">
						<div>销售价：<span style="color: red;">{{ record.nowPrice }}</span>元</div>
						<div>成本价：<span style="color: rgb(20, 205, 114);">{{ record.costPrice }}</span>元</div>
						<div>市场价：<span style="color: rgb(24, 144, 255);">{{ record.originPrice }}</span>元</div>
					</template>
					<template v-if="column.key === 'stock'">
						<div>现有：<span>{{ record.stock }}</span></div>
						<div>预警：<span>{{ record.stockAlert }}</span></div>
					</template>
					<template v-if="column.key === 'sell'">
						<div>基础：<span style="color: rgb(252, 169, 89);">{{ record.virtualSoldNum }}</span></div>
						<div>实际：<span style="color: rgb(20, 205, 114);">{{ record.salesVolume }}</span></div>
					</template>

					<template v-if="column.key === 'deliveryType'">
						{{ ['无', '自提', '快递'][record.deliveryType - 1] }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-else-if="column.key === 'isShelf'">
						{{ record.isShelf === 0 ? '已下架' : '已上架' }}
					</template>
					<template v-else-if="column.key === 'action'">
						<a-button v-permission="['ledger_setting_snack_group']" type="link" @click="onSelect(0, record)">选择分账组</a-button>
					</template>
				</template>
			</a-table>

			<a-modal v-model:visible="showModal" destroyOnClose width="800px" title="选择分账组" @ok="onChangeGroup">
				<a-spin :spinning="loading">
					<group v-model:value="modelRef.allocateGroupId"></group>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import group from '@/views/ledger/goodsSetting/components/group.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getStoreGoodsList } from '@/service/modules/goods.js';
	import { bindAllocateGroup, batchBindAllocateGroup, ledgerGroupList } from '@/service/modules/ledger.js';
	export default {
		components: { Icon, group },
		data() {
			return {
				loading: false,
				title: '',
				organizationList: [],
				cinemaAllList: [],
				groupList: [],
				formState: {
					saleType: 2,
					// organizationId: 0,
					cinemaId: 0,
					allocateGroupId: 0
				},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '分账组',
					dataIndex: 'allocateGroupName'
				},{
					title: '所属影院',
					dataIndex: 'cinemaName'
				},{
					title: '商城名称',
					dataIndex: 'storeTitle'
				}, {
					title: '卖品信息',
					key: 'goods'
				}, {
					title: '卖品图片',
					key: 'img',
					width: 130
				}, {
					title: '卡信息',
					key: 'card'
				},{
					title: '价格信息',
					key: 'price',
					width: 180
				}, {
					title: '参与记录信息',
					key: 'part',
					width: 180
				}, {
					title: '销量',
					key: 'sell',
					width: 180
				}, {
					title: '库存信息',
					key: 'stock',
					width: 180
				}, {
					title: '配送方式',
					key: 'deliveryType',
					width: 150
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 200
				}, {
					title: '状态',
					key: 'isShelf',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				modelRef: {},
				type: 0
			}
		},
		created() {
			this.getOrganizationList();
			this.getLedgerGroupList();
			// this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.allocateGroupId = this.searchData.allocateGroupId ? this.searchData.allocateGroupId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getStoreGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 2,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getLedgerGroupList() {
				let ret = await ledgerGroupList({
					page: 1,
					pageSize: 999999,
				})
				if (ret.code === 200) {
					let data = ret.data.list;
					for (let i = 0; i < data.length; i++) {
						if (data[i].isDefault) {
							this.title = data[i].title;
							break;
						}
					}
					this.groupList = ret.data.list;
				}
			},
			onSelect(type, item) {
				this.type = type;
				if (this.type) {
					this.modelRef.id = 0;
					this.modelRef.allocateGroupId = [];
				} else {
					this.modelRef.id = item.id;
					this.modelRef.allocateGroupId = [item.allocateGroupId];
				}
				this.showModal = true;
			},
			onChangeGroup() {
        if (!this.modelRef.allocateGroupId || (this.modelRef.allocateGroupId && !this.modelRef.allocateGroupId[0])) {
          this.$confirm({
            title: '提示',
            content: '当前未选择分账组, 确定清空分账组?',
            onOk: () => {
              this.onConfirmChange();
            },
          })
          return;
        }
        this.onConfirmChange();
			},
      async onConfirmChange() {
        this.loading = true;
        try {
          let ret;
          if (this.type) {
            ret = await batchBindAllocateGroup({
              ids: this.selectedRowKeys.join(','),
              allocateGroupId: (this.modelRef.allocateGroupId && this.modelRef.allocateGroupId[0]) ? this.modelRef.allocateGroupId[0] : 0
            });
          } else {
            ret = await bindAllocateGroup({
              id: this.modelRef.id,
              allocateGroupId: (this.modelRef.allocateGroupId && this.modelRef.allocateGroupId[0]) ? this.modelRef.allocateGroupId[0] : 0
            });
          }
          this.loading = false;
          if (ret.code === 200) {
            this.$message.success('修改成功');
            if (this.type) {
              this.selectedRowKeys = [];
              this.selectedListData = [];
              this.modelRef.allocateGroupId = [];
            }
            this.showModal = false;
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      },
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('最大可选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('选择数据不允许大于500条');
								return;
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
